import React from 'react';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function Footer(props) {
  return (
    <>
      <footer className="page-footer font-small pt-5 mt-5">
        <Container fluid className="text-center">
          <Row noGutters>
            <Col>
              <small className="text-muted">
                Hosted with <a href="https://www.netlify.com/">Netlify</a>
              </small>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
