import React from 'react';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';

import Footer from './Footer';
import Header from './Header';

import './App.css';

const images = [
  'aliens.jpg',
  'arguing.jpg',
  'cutting.jpg',
  'gaming.jpg',
  'grating.jpg',
  'kid.jpg',
  'mac and cheese.jpg',
  'special.jpg',
  'xkcd.png',
  'bettse_I_use_a_mac.png',
  'macpro.jpg',
];

function App() {
  function carouselItems() {
    return images.map((image, i) => {
      return (
        <Carousel.Item key={i}>
          <Image
            fluid
            src={`${process.env.PUBLIC_URL}/images/${image}`}
            alt={`Funny use of a mac`}
          />
        </Carousel.Item>
      );
    });
  }

  const starting = Math.floor(Math.random() * images.length);

  return (
    <>
      <Header />
      <Container fluid="md">
        <Row className="text-center justify-content-center">
          <Col>
            <Carousel
              controls={false}
              defaultActiveIndex={starting}
              indicators={false}
              pause={false}>
              {carouselItems()}
            </Carousel>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default App;
